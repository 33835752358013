<template>
  <!-- 新增患病-->
  <div class="contain">
    <div class="header-title">
      <!-- <span>{{ pageTitle }}</span>
      <el-button class="return-button" @click="onCancel" >返回</el-button> -->
      <el-page-header @back="onCancel" :content="pageTitle">
        <template slot="title">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </template>
      </el-page-header>
    </div>
    <div class="tab-button" v-if="type != 'view'">
      <div class="tab-item first" :class="{ active: editTab == '1' }" @click="changeTab('1')" :style="{ 'background-image': editTab == '1' ? 'url('+tabFirstActiveBg+')': 'url('+tabFirstBg+')','background-size': '100% 100%', cursor: editTab == '3' ? 'unset' : 'pointer' }">基础设置</div>
      <div class="tab-item second" :class="{ active: editTab == '2' }" @click="changeTab('2')" :style="{ 'background-image': editTab == '2' ? 'url('+tabSecondActiveBg+')': 'url('+tabSecondBg+')','background-size': '100% 100%',transform: 'translateX(-0.41667vw)', cursor: editTab == '3' ? 'unset' : 'pointer' }">评分项目</div>
      <div class="tab-item third" :class="{ active: editTab == '3' }" @click="changeTab('3')" :style="{ 'background-image': editTab == '3' ? 'url('+tabThirdActiveBg+')': 'url('+tabThirdBg+')','background-size': '100% 100%',transform: 'translateX(-0.78125vw)', cursor: editTab == '3' ? 'unset' : 'pointer' }">预览、完成</div>
    </div>
    <div class="tab-button" v-if="type == 'view'">
      <div class="tab-item first" :class="{ active: viewTab == '1' }" @click="changeTab('1')" :style="{ 'background-image': viewTab == '1' ? 'url('+tabFirstActiveBg+')': 'url('+tabFirstBg+')','background-size': '100% 100%' }">基础设置</div>
      <div class="tab-item third" :class="{ active: viewTab == '2' }" @click="changeTab('2')" :style="{ 'background-image': viewTab == '2' ? 'url('+tabThirdActiveBg+')': 'url('+tabThirdBg+')','background-size': '100% 100%',transform: 'translateX(-0.41667vw)' }">评分项目</div>
    </div>
    <div class="form" v-show="type != 'view' && editTab == '1' || (type == 'view' && viewTab == '1')">
      <el-form :model="superviseTemplateForm" style="width: 80%;" :rules="type == 'view' ? {} : rules" ref="superviseTemplateForm" :label-width="labelWidth" class="demo-ruleForm" :disabled="type == 'view'">
        <el-form-item label="模板名称:" prop="templateName" ref="templateName">
          <el-input v-model.trim="superviseTemplateForm.templateName" class="elInput" :maxlength="20" placeholder="请输入模板名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="描述:" prop="description" ref="description">
          <el-input type="textarea" :rows="4" placeholder="请输入描述" v-model="superviseTemplateForm.description" resize="none" :maxlength="200"></el-input>
        </el-form-item>
        <el-form-item label="标签:" prop="label" ref="label">
          <el-select v-model="superviseTemplateForm.label" clearable placeholder="请选择标签">
            <el-option v-for="item in labelOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="督导方式:" prop="supervisionMethod" ref="supervisionMethod">
          <el-radio-group v-model="superviseTemplateForm.supervisionMethod" @change="handeleSuperviseMethodChange">
            <el-radio :label="'1'">远程督导</el-radio>
            <el-radio :label="'2'">现场督导</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序:">
          <el-input v-model="superviseTemplateForm.sort" v-input-number class="elInput" :maxlength="3" placeholder="请输入排序" @keyup.native="getInputChange(superviseTemplateForm.sort,'sort')" clearable></el-input>
        </el-form-item>
        <el-form-item label="是否启用:" prop="isEnable" ref="isEnable">
          <el-radio-group v-model="superviseTemplateForm.isEnable">
            <el-radio :label="'1'">启用</el-radio>
            <el-radio :label="'0'">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="督导图标:" prop="supervisionIcon" ref="supervisionIcon">
          <el-popover placement="right-start" width="480" trigger="manual" popper-class="show-icon-poper" v-model="iconPopervisible">
            <div class="show-icon-content">
              <div class="title">
                <span>图标库</span>
                <i class="el-icon-close" @click="onCloseIcon"></i>
              </div>
              <div class="body">
                <div class="icon-list">
                  <div class="icon-item" :class="{ active: iconInfo.id == item.id }" v-for="item in iconOptions" :key="item.id">
                    <img :src="item.scheduleIcon" alt="" @click="getIcon(item)">
                  </div>
                </div>
              </div>
              <div class="footer">
                <el-button class="icon-cancel" @click="onCloseIcon">取消</el-button>
                <el-button class="icon-submit" @click="onSubmitIcon">保存</el-button>
              </div>
            </div>
            <el-button class="text-button" @click="chooseIcon" type="text" slot="reference">{{ superviseTemplateForm.supervisionIcon ? '更换图标' : '选择图标' }}</el-button>
          </el-popover>
          <img class="icon-show-img" v-if="superviseTemplateForm.supervisionIcon" :src="superviseTemplateForm.supervisionIconImg" alt="">
        </el-form-item>
      </el-form>
    </div>
    <div class="form" v-show="type != 'view' && editTab == '2' || (type == 'view' && viewTab == '2')">
      <div class="expand-radio">
        <el-radio-group v-model="expensionType" @change="handleExpension">
          <el-radio :label="'1'">展开一级</el-radio>
          <el-radio :label="'2'">展开二级</el-radio>
          <el-radio :label="'3'">展开三级</el-radio>
          <el-radio :label="'all'">展开全部</el-radio>
        </el-radio-group>
      </div>
      <el-table :data="tableData" :header-cell-style="{ background: '#F1F4F9', color: '#5E6D9B', fontWeight: '400', 'text-align':'center' }" v-loading="tableLoading" :expand-row-keys="expandRow" show-summary :sum-text="'总分'" :summary-method="getSummaries" @select="handleTableSelect" @select-all="handleTableAllSelect" ref="projectTable" row-key="id" :tree-props="{ children: 'supProList', hasChildren: 'hasChildren' }">
        <el-table-column type="selection" width="55" v-if="type != 'view'"></el-table-column>
        <el-table-column prop="projectName" label="督导项目" min-width="400">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" popper-class="customPopper" :content="scope.row.projectName" placement="top" v-if="scope.row.projectName">
              <div class="description-div project bold" v-if="scope.row.level != '4'">{{ scope.row.projectName }}</div>
              <div class="description-div project" v-else>{{ scope.row.projectName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="总分" align="center" min-width="100">
          <template slot-scope="scope">
            <span :class="{ error: scope.row.type == '2' }" v-if="scope.row.level == '4'">{{ scope.row.score }}</span>
            <span v-else>{{ scope.row.score }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" align="center" min-width="100">
          <template slot-scope="scope">
            <span :class="{ error: scope.row.type == '2' }" v-if="scope.row.level == '4'">{{ scope.row.type == '2' ? '扣分题' : scope.row.type == '1' ? '得分题' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="250">
          <template slot-scope="scope">
            <el-button class="table-handle" @click="handleView(scope.row)" type="text" v-if="scope.row.level == '4'">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="form" v-show="type != 'view' && editTab == '3'">
      <div class="success-info">
        <img :src="settingSuccessIcon" alt="">
        <div class="success-text">模板设置成功！</div>
      </div>
      <div class="view-buttons">
        <div class="button-item" @click="changeViewTab('1')" :class="{ active: viewType == '1' }">电脑预览</div>
        <div class="button-item" @click="changeViewTab('2')" :class="{ active: viewType == '2' }">手机预览</div>
      </div>
      <div class="view-body" v-show="viewType == '1'">
        <div class="view-body-title">{{ superviseTemplateForm.templateName }}</div>
        <div class="view-body-remark">{{ superviseTemplateForm.description }}</div>
        <div class="dashed-line"></div>
        <div class="view-body-content-choose">
          <div class="label">督导内容：</div>
          <div class="item">
            <el-select v-model="projectContent" placeholder="请选择" @change="handleProjectLevelChange">
              <el-option v-for="item in projectContentOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="view-body-content-list">
          <div class="view-body-content-item" v-for="item in projectContentList" :key="item.id">
            <div class="item-title">{{ item.projectName }}</div>
            <div class="item-options" v-for="info in item.fourLevelProject" :key="info.id">
              <div class="options-question">{{ info.optionsNum }}、{{ info.projectName }}</div><!--{{ info.totalScore ? '（'+info.totalScore+'分）' : '' }}-->
              <div class="options-item" v-for="optionInfo in info.options" :key="optionInfo.id">
                <div class="item-info">
                  <div class="circle"></div>
                  <div class="text">{{ optionInfo.projectName }}</div>
                </div>
                <div class="score"><span v-if="optionInfo.scoreType=='2'"></span>{{ optionInfo.score }}分</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="view-body phone" v-show="viewType == '2'">
        <div class="phone-view-inside" :style="{ 'background-image': 'url('+phoneViewBg+')', 'background-size': '100% 100%' }">
          <div class="view-body-content-choose phone">
            <el-select v-model="projectContent" placeholder="请选择" class="phone-select" @change="handleProjectLevelChange">
              <el-option v-for="item in projectContentOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="view-body-content-list phone">
            <div class="view-body-content-list-inside">
              <div class="view-body-content-item phone" v-for="item in projectContentList" :key="item.id">
                <!-- <div class="item-title ">{{ item.firstLevelName }}</div> -->
                <div class="item-options phone" v-for="info in item.optionsList" :key="info.id">
                  <div class="options-question phone">{{ info.optionsNum }}、{{ info.projectName }}</div><!--{{ info.totalScore ? '（'+info.totalScore+'分）' : '' }}-->
                  <div class="options-item phone" v-for="optionInfo in info.options" :key="optionInfo.id">
                    <div class="item-info">
                      <div class="circle"></div>
                      <div class="text">{{ optionInfo.projectName }}</div>
                    </div>
                    <div class="score">{{ optionInfo.score }}分</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-btn" v-if="type != 'view' && editTab != '3'">
      <el-button class="cancel" @click="onPrev" v-show="editTab == '2'">上一步</el-button>
      <el-button class="submit" @click="onNext">下一步</el-button>
    </div>
    <el-drawer
      title="督导项目详情"
      :append-to-body="true"
      :destroy-on-close="true"
      :visible.sync="drawerVisible"
      :direction="'rtl'"
      :size="'50%'"
      :before-close="handleDrawerClose">
      <supervise-project-detail :projectId="projectId" :showType="showType" v-if="drawerVisible"></supervise-project-detail>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import delIcon from "@/assets/img/del-icon.png"; //删除icon
import taskAddIcon from "@/assets/img/task-add-icon.png"; //添加icon
import tabFirstActiveBg from '@/assets/img/tab-first-active-bg.png'; //tab第一个选中bg
import tabFirstBg from '@/assets/img/tab-first-bg.png'; //tab第一个bg
import tabSecondActiveBg from '@/assets/img/tab-second-active-bg.png'; //tab第二个选中bg
import tabSecondBg from '@/assets/img/tab-second-bg.png'; //tab第二个bg
import tabThirdActiveBg from '@/assets/img/tab-third-active-bg.png'; //tab第三个选中bg
import tabThirdBg from '@/assets/img/tab-third-bg.png'; //tab第三个bg
import settingSuccessIcon from '@/assets/img/setting-success-icon.png'; //设置成功icon
import phoneViewBg from '@/assets/img/phone-view-bg.png'; //手机预览bg
import SuperviseProjectDetail from '@/views/supervise/superviseProject/detail.vue'
export default {
  name: 'superviseTemplateDetail',
  components: { SuperviseProjectDetail },
  data() {
    return {
      editTab: '1', //编辑tab
      viewTab: '1',//查看tab
      superviseTemplateForm: {
        templateName: "", //模板名称
        description: "", //描述
        label: "", //标签
        supervisionMethod: "", //督导方式
        sort: "0", //排序
        isEnable: '1', //是否启用
        supervisionIcon: '',//督导图标
        supervisionIconImg: '',//督导图标图片
      },
      rules: {
        templateName: [{ required: true, message: "请输入模板名称", trigger: "blur" },],
        description: [{ required: true, message: "请输入描述", trigger: "blur" }],
        label: [{ required: true, message: "请选择标签", trigger: "change" },],
        supervisionMethod: [{ required: true, message: "请选择督导方式", trigger: "change" },],
        isEnable: [{ required: true, message: "请选择是否启用", trigger: "change" },],
        supervisionIcon: [{ required: true, message: "请选择督导图标", trigger: "change" },],
      },
      labelOptions: [
        {
          name: '常规督导',
          value: '1'
        },
        {
          name: '专项督导',
          value: '2'
        }
      ],//标签下拉

      id: null, //模板id
      type: '', //编辑类型
      jumpType: '', //跳转类型
      pageTitle: '', //页面标题

      iconInfo: {}, //选中的图标
      iconPopervisible: false,//iconpoper展开标识
      iconOptions:[],//icon选项

      tableData: [],//项目list
      tableDataAll: [],//所有项目展开
      tableSelectData: [],//项目选择数据
      itemTemplateTempList: [],//回显出来的选项list

      expandRow: [],
      expandRowItem: [],
      expensionType: 'all', //展示全部

      totalInfo: {
        totalScore: 0,
        totalOptions: 0,
      },
      tableLoading: false,

      drawerVisible: false,//查看标识
      projectId: '',//项目id
      showType: '',//显示方式

      viewType: '1',//预览类型
      projectContent: '',//督导内容下拉选中项
      projectContentOptions: [], //督导内容下拉list
      projectContentList: [], //督导内容与选项list


      delIcon,// 删除icon
      taskAddIcon, //添加题目icon
      tabFirstActiveBg,//tab第一个选中bg
      tabFirstBg,//tab第一个bg
      tabSecondActiveBg,//tab第二个选中bg
      tabSecondBg,//tab第二个bg
      tabThirdActiveBg,//tab第三个选中bg
      tabThirdBg,//tab第三个bg
      settingSuccessIcon,//设置成功icon
      phoneViewBg,//设置成功icon

      labelWidth: '120px',
      branchId:'',
    };
  },
  computed: {
    ...mapState({
      fileTypeList: (state) => state.common.fileTypeList,
      userInfo: (state) => state.userInfo,
    })
  },
  //路由进入前改变title
  beforeRouterEnter(to, from, next){
    if(to.query.type == 'edit'){
      to.meta.title = '督导模板编辑'
    } else if(to.query.type == 'add'|| to.query.type == 'copy'){
      to.meta.title = '督导模板新增'
    } else if(to.query.type == 'view'){
      to.meta.title = '督导模板详情'
    }
    next()
  },
  async created(){
    if(this.$route.query.type == 'edit'){
      this.pageTitle = '督导模板编辑'
    } else if(this.$route.query.type == 'add'|| this.$route.query.type == 'copy'){
      this.pageTitle = '督导模板新增'
    } else if(this.$route.query.type == 'view'){
      this.pageTitle = '督导模板详情'
    }

    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList") ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList")) : null
    if(chooseMenuInfoList){
      chooseMenuInfoList.map((item) => {
        if(item.path == this.$route.path){
          item.meta.title = this.pageTitle
        }
      })
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList)) 
      breadCrumbList.shift()
      console.log(breadCrumbList)
      this.$store.commit("common/getBreadcrumbList",breadCrumbList || [])
    }

    this.type = this.$route.query.type
    this.jumpType = this.$route.query.jumpType
    this.id = this.$route.query.id
    await this.getIconOptions()
    if(this.$route.query.id){
      this.getTemplateDetail()
    }
  },
  mounted(){
    window.addEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if(window.innerWidth < 1300){
      this.labelWidth = '80px'
    } else {
      this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
    }


     if (this.userInfo.schoolList.length > 0) {
      this.branchId = this.userInfo.schoolList[0].companyLabel;
    }
  },
  methods: {
    //取消
    onCancel() {
      this.$router.back(-1)
    },
    //改变tab
    changeTab(val){
      if(this.editTab == '3' && this.type != 'view' || (this.editTab == '1' && val == '3' && this.type != 'view')){
        return
      }
      if(val == this.editTab && this.type != 'view' || val == this.viewTab && this.type == 'view'){
        return
      }
      if(this.type == 'view'){
        this.viewTab = val
        if(val == '2'){
          this.getTableData(this.superviseTemplateForm.supProList)
        }
      } else {
        if(val == '1'){
          this.onPrev()
        } else {
          this.onNext()
        }
      }
    },
    //改变预览tab
    changeViewTab(val){
      if(val == this.viewType){
        return
      }
      this.viewType = val
    },
    //处理选择图标
    chooseIcon(){
      this.iconPopervisible = true
    },
    //处理选中图标
    getIcon(item){
      this.iconInfo = JSON.parse(JSON.stringify(item))
    },
    //处理提交icon
    onSubmitIcon(){
      this.iconPopervisible = false
      this.superviseTemplateForm.supervisionIcon = this.iconInfo.id
      this.superviseTemplateForm.supervisionIconImg = this.iconInfo.scheduleIcon
    },
    //处理关闭poper
    onCloseIcon(){
      this.iconPopervisible = false
    },
    //获取图标库list
    async getIconOptions(){
      await this.$api.getAllIconList().then((res) => {
        if(res.data.code == 0){
          console.log(res.data.data)
          this.iconOptions = res.data.data
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {})
    },
    //获取模板详情
    getTemplateDetail(){
      this.$api.getTemplateInfoById(this.id,{ delFlag: '2' }).then((res) => {
        if(res.data.code == 0){
          let result = res.data.data
          let iconInfo = this.iconOptions.find((info) => { return info.id == result.supervisionIcon })
          this.superviseTemplateForm = {
            templateName: result.templateName,
            description: result.description,
            label: result.label,
            supervisionMethod: result.supervisionMethod,
            sort: result.sort || '0',
            isEnable: result.isEnable || '1',
            supervisionIcon: result.supervisionIcon,
          }
          let fourList = []
          if(result.supProList && result.supProList.length > 0){
            result.supProList.map((item) => {
              if(item.supProList && item.supProList.length > 0){
                item.supProList.map((info) => {
                  if(info.supProList && info.supProList.length > 0){
                    info.supProList.map((thirdInfo) => {
                      if(thirdInfo.supProList && thirdInfo.supProList.length > 0){
                        thirdInfo.supProList.map((fourInfo) => {
                          fourList.push(fourInfo)
                        })
                      }
                    })
                  }
                })
              }
            })
          }
          if(this.type == 'view'){
            this.superviseTemplateForm.supProList = result.supProList || []
          }
          this.itemTemplateTempList = fourList
          if(iconInfo){
            this.iconInfo = iconInfo
            this.superviseTemplateForm.supervisionIconImg = iconInfo.scheduleIcon
          }
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    //改变输入按键事件
    getInputChange(value,type){
      let resultValue = value.replace(/[^\w\.\/]/ig,'')
      this.superviseTemplateForm[type] = resultValue
    },
    //处理督导方式变化
    handeleSuperviseMethodChange(val){
      this.itemTemplateTempList = []
      this.tableSelectData = []
    },
    //处理选中
    handleTableSelect(selection,row){
      let list = []
      let delList = []
      let index = selection.findIndex((item) => { return item.id === row.id })
      if(index === -1){//主动点掉
        if(row.level !== '4'){//不是最低级--向下找需要点掉的选项
          let infoList = this.getList(row.level,row.supProList)
          delList = delList.concat(infoList)
        } else if(row.level === '4'){//是最低级--直接添加到要点掉的list里
          delList.push(row)
        }
        if(row.level !== '1'){//对于不是最外层的选项--要向上找需要点掉的选项
          let upInfoList = this.getUpList(row.level,row)
          delList = delList.concat(upInfoList)
        }
        delList.map((item) => {//在已选的选项中删除要点掉的选项
          let index = selection.findIndex((info) => { return info.id === item.id })
          if(index != -1){
            selection.splice(index,1)
          }
        })
        list = selection //赋值最终已选选项list
      } else {//主动增加
        selection.map((item) => {
          let index = list.findIndex((info) => { return info.id === item.id })
          if(item.level !== '4' && index === -1){//对于不是最低级并且不存在最终已选选项list中的选项--向下找需要选中的选项
            let infoList = this.getList(item.level,item.supProList)
            infoList.map((result) => {
              let resultIndex = list.findIndex((info) => { return info.id === result.id })
              if(resultIndex == -1){
                list.push(result)
              }
            })
            list.push(item)
          } else if(item.level === '4' && index === -1){//对于是最低级并且不存在最终已选选项list中的选项--直接放入需要选中的选项list
            list.push(item)
          }
          if(item.level !== '1'){//对于不是最外级的选项--向上找需要选中的选项
            let upInfoList = this.getUpChooseList(item.level,item,list)
            list = upInfoList
          }
        })
      }
      console.log(list)
      this.tableSelectData = list;
      this.tableDataAll.map((item) => {
        let index = list.findIndex((info) => { return info.id == item.id })
        if(index != -1){
          this.$refs.projectTable.toggleRowSelection(item,true)
        } else {
          this.$refs.projectTable.toggleRowSelection(item,false)
        }
      })
    },
    //处理全选
    handleTableAllSelect(selection){
      if(selection.length > 0){
        let selectList = selection.filter((item) => { return item.isHaveFour == true })
        let selectData = []
        selectList.map((firstInfo) => {
          selectData.push(firstInfo)
          firstInfo.supProList.map((secondInfo) => {
            selectData.push(secondInfo)
            secondInfo.supProList.map((thirdInfo) => {
              selectData.push(thirdInfo)
              thirdInfo.supProList.map((fourInfo) => {
                selectData.push(fourInfo)
              })
            })
          })
        })
        this.tableSelectData = selectData;
        this.tableDataAll.map((item) => {
          let index = selectData.findIndex((info) => { return info.id == item.id })
          if(index != -1){
            this.$refs.projectTable.toggleRowSelection(item,true)
          } else {
            this.$refs.projectTable.toggleRowSelection(item,false)
          }
        })
      } else {
        this.tableSelectData = []
        this.$refs.projectTable.clearSelection()
      }
    },
    //处理选中项的上级是否需要选中的方法
    getUpChooseList(level,row,list){
      let firstInfo = {}
      let firstNum = 0
      let secondInfo = {}
      let secondNum = 0
      let thirdInfo = {}
      let thirdNum = 0
      console.log(this.tableData)
      switch(level){
        case '2':
          this.tableData[row.firstIndex].supProList.map((item) => {
            let index = list.findIndex((info) => { return info.id === item.id })
            if(index != -1){
              firstNum ++
            }
          })
          if(firstNum == this.tableData[row.firstIndex].supProList.length){
            let firstIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].id })
            if(firstIndex == -1){
              firstInfo = this.tableData[row.firstIndex]
              list = list.concat([firstInfo])
            }
          }
          break;
        case '3':
          this.tableData[row.firstIndex].supProList[row.sendIndex].supProList.map((item) => {
            let index = list.findIndex((info) => { return info.id === item.id })
            if(index != -1){
              secondNum ++
            }
          })
          if(secondNum == this.tableData[row.firstIndex].supProList[row.sendIndex].supProList.length){
            let secondIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].supProList[row.sendIndex].id })
            if(secondIndex == -1){
              secondInfo = this.tableData[row.firstIndex].supProList[row.sendIndex]
              list = list.concat([secondInfo])
            }
          }
          this.tableData[row.firstIndex].supProList.map((item) => {
            let index = list.findIndex((info) => { return info.id === item.id })
            if(index != -1){
              firstNum ++
            }
          })
          if(firstNum == this.tableData[row.firstIndex].supProList.length){
            let firstIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].id })
            if(firstIndex == -1){
              firstInfo = this.tableData[row.firstIndex]
              list = list.concat([firstInfo])
            }
          }
          break;
        case '4':
          console.log(row)
          console.log(this.tableData[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList)
          this.tableData[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList.map((item) => {
            let index = list.findIndex((info) => { return info.id === item.id })
            if(index != -1){
              thirdNum ++
            }
          })
          if(thirdNum == this.tableData[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList.length){
            let thirdIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].id })
            if(thirdIndex == -1){
              thirdInfo = this.tableData[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex]
              list = list.concat([thirdInfo])
            }
          }
          this.tableData[row.firstIndex].supProList[row.sendIndex].supProList.map((item) => {
            let index = list.findIndex((info) => { return info.id === item.id })
            if(index != -1){
              secondNum ++
            }
          })
          if(secondNum == this.tableData[row.firstIndex].supProList[row.sendIndex].supProList.length){
            let secondIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].supProList[row.sendIndex].id })
            if(secondIndex == -1){
              secondInfo = this.tableData[row.firstIndex].supProList[row.sendIndex]
              list = list.concat([secondInfo])
            }
          }
          this.tableData[row.firstIndex].supProList.map((item) => {
            let index = list.findIndex((info) => { return info.id === item.id })
            if(index != -1){
              firstNum ++
            }
          })
          if(firstNum == this.tableData[row.firstIndex].supProList.length){
            let firstIndex = list.findIndex((info) => { return info.id === this.tableData[row.firstIndex].id })
            if(firstIndex == -1){
              firstInfo = this.tableData[row.firstIndex]
              list = list.concat([firstInfo])
            }
          }
          break;
        default:
          break;
      }
      return list
    },
    //处理需要点掉选项向上找需要点掉的上级元素
    getUpList(level,row){
      let list = []
      let firstInfo = {}
      let secondInfo = {}
      let thirdInfo = {}
      console.log(row)
      switch(level){
        case '2':
          firstInfo = this.tableData[row.firstIndex]
          list = list.concat([firstInfo])
          break;
        case '3':
          firstInfo = this.tableData[row.firstIndex]
          secondInfo = this.tableData[row.firstIndex].supProList[row.sendIndex]
          list = list.concat([firstInfo]).concat([secondInfo])
          break;
        case '4':
          firstInfo = this.tableData[row.firstIndex]
          secondInfo = this.tableData[row.firstIndex].supProList[row.sendIndex]
          thirdInfo = this.tableData[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex]
          list = list.concat([firstInfo]).concat([secondInfo]).concat([thirdInfo])
          break;
        default:
          break;
      }
      return list
    },
    //处理向下找需要点掉/选中的选项的筛选
    getList(level,handleList){
      let list = []
      switch(level){
        case '1':
          handleList.map((firstInfo) => {
            list.push(firstInfo)
            firstInfo.supProList.map((secondInfo) => {
              list.push(secondInfo)
              secondInfo.supProList.map((thirdInfo) => {
                list.push(thirdInfo)
              })
            })
          })
          break;
        case '2':
          handleList.map((firstInfo) => {
            list.push(firstInfo)
            firstInfo.supProList.map((secondInfo) => {
              list.push(secondInfo)
            })
          })
          break;
        case '3':
          handleList.map((firstInfo) => {
            list.push(firstInfo)
          })
          break;
        default:
          break;
      }
      return list
    },
    //处理计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let score = 0
      let topic = 0
      console.log(this.tableSelectData.filter((item) => { return item.level === '4' }))
      columns.forEach((column, index) => {
        if (index === 1 && this.type != 'view' || (index === 0 && this.type == 'view')) {
          sums[index] = '总分';
          return;
        }
        if (index === 3 && this.type != 'view' || (index === 2 && this.type == 'view')) {
          sums[index] = '总题数';
          return;
        }
        if(this.tableSelectData.length == 0 && (index !== 0 && this.type != 'view' || this.type == 'view')){
          sums[index] = 0;
        } else if(this.tableSelectData.length > 0 && (index !== 0 && this.type != 'view' || this.type == 'view')){
          if(index === 2 && this.type != 'view' || (index === 1 && this.type == 'view')){
            this.tableSelectData.map((item) => {
              if(item.level === '4' && item.type == '1'){
                score = score + Number(item.score)
              }//获取最低级计算total值
            })
            sums[index] = score
          }
          if(index === 4 && this.type != 'view' || (index === 3 && this.type == 'view')){
            this.tableSelectData.map((item) => {
              if(item.level === '4'){
                topic = topic + 1
              }//获取最低级计算total值
            })
            sums[index] = topic
            // sums[index] = this.totalInfo.totalOptions;
          }
        }
      });

      return sums;
    },
    //处理查看
    handleView(row){
      this.drawerVisible = true
      this.$nextTick(() => {
        this.projectId = row.id+''
        this.showType = 'view'
      })
    },
    //处理查看关闭
    handleDrawerClose(){
      this.drawerVisible = false
      this.$nextTick(() => {
        this.projectId = ''
        this.showType = ''
      })
    },
    //上一步
    onPrev(){
      this.editTab = '1'
    },
    //下一步
    onNext() {
      if(this.editTab == '1'){
        this.$refs.superviseTemplateForm.validate((valid, object) => {
          if (valid) {
            if(this.type == 'add'|| this.type == 'copy' || this.type == 'edit'){
              // 验证名称是否重复
              let params = {
              templateName: this.superviseTemplateForm.templateName,
              description: this.superviseTemplateForm.description,
              label: this.superviseTemplateForm.label,
              supervisionMethod: this.superviseTemplateForm.supervisionMethod,
              sort: this.superviseTemplateForm.sort,
              isEnable: this.superviseTemplateForm.isEnable,
              supervisionIcon: this.superviseTemplateForm.supervisionIcon,
              }
              params.orgId = this.jumpType == 'headquarters' ? '' : this.branchId
              params.orgName = this.jumpType == 'headquarters' ? '' : ''
              params.level = this.jumpType == 'headquarters' ? '1' : '2'
              params.templateId = this.id
              if(this.type == 'add'){
                  params.flag = 'add'
              }else if(this.type == 'copy'){
                  params.flag = 'copy'
              }else{
                  params.flag = 'edit'
              }
              let projectList = []
              let score = 0
              let topic = 0
              this.tableSelectData.map((item) => {
                if(item.level === '4'){
                  let list = [ { projectId: item.id } ]
                  let thirdInfo = this.tableData[item.firstIndex].supProList[item.sendIndex].supProList[item.thirdIndex]
                  let thirdIndex = projectList.findIndex((info) => { return info.id == thirdInfo.id })
                  let secondInfo = this.tableData[item.firstIndex].supProList[item.sendIndex]
                  let secondIndex = projectList.findIndex((info) => { return info.id == secondInfo.id })
                  let firstInfo = this.tableData[item.firstIndex]
                  let firstIndex = projectList.findIndex((info) => { return info.id == firstInfo.id })
                  if(thirdIndex == -1){
                    list.push({ projectId: thirdInfo.id })
                  }
                  if(secondIndex == -1){
                    list.push({ projectId: secondInfo.id })
                  }
                  if(firstIndex == -1){
                    list.push({ projectId: firstInfo.id })
                  }
                  projectList = projectList.concat(list)
                  score = item.type != '1' ? score : (score + Number(item.score))
                  topic = topic + 1
                }
              })
              params.totalScore = score
              params.totalQuestions = topic
              params.itemTemplateTempList = projectList
              this.buttonloading = true
                this.$api.superviseTemplateCheckName(params).then((res)=>{
                    if(res.data.code == '0'){
                        let params = this.superviseTemplateForm;
                        this.editTab = '2'
                        if(this.tableSelectData.length == 0){
                          this.tableLoading = true
                          this.getProjectList()
                        }
                    }else{
                        this.$message.error(res.data.msg)
                    }
                    this.buttonloading = false
                }).catch(error=>{
                    this.buttonloading = false
                })
            }else{
                let params = this.superviseTemplateForm;
                this.editTab = '2'
                if(this.tableSelectData.length == 0){
                  this.tableLoading = true
                  this.getProjectList()
                }
            }
        
          } else {
            for (let i in object) {
              let dom = this.$refs[i]
              dom.$el.scrollIntoView({
                //滚动到指定节点
                block: 'center', //值有start,center,end，nearest，当前显示在视图区域中间
                behavior: 'smooth' //值有auto、instant,smooth，缓动动画（当前是慢速的）
              })
              break
            }
            console.log("error submit!!");
            return false;
          }
        });
      } else if(this.editTab == '2'){
        if(this.tableSelectData.length === 0){
          this.$message.warning("请选择项目");
          return;
        }
        console.log(this.tableSelectData)
        this.buttonloading = true
        let params = {
          templateName: this.superviseTemplateForm.templateName,
          description: this.superviseTemplateForm.description,
          label: this.superviseTemplateForm.label,
          supervisionMethod: this.superviseTemplateForm.supervisionMethod,
          sort: this.superviseTemplateForm.sort,
          isEnable: this.superviseTemplateForm.isEnable,
          supervisionIcon: this.superviseTemplateForm.supervisionIcon,
        }
        params.orgId = this.jumpType == 'headquarters' ? '' : this.branchId
        params.orgName = this.jumpType == 'headquarters' ? '' : ''
        params.level = this.jumpType == 'headquarters' ? '1' : '2'
        let projectList = []
        let score = 0
        let topic = 0
        this.tableSelectData.map((item) => {
          if(item.level === '4'){
            let list = [ { projectId: item.id } ]
            let thirdInfo = this.tableData[item.firstIndex].supProList[item.sendIndex].supProList[item.thirdIndex]
            let thirdIndex = projectList.findIndex((info) => { return info.id == thirdInfo.id })
            let secondInfo = this.tableData[item.firstIndex].supProList[item.sendIndex]
            let secondIndex = projectList.findIndex((info) => { return info.id == secondInfo.id })
            let firstInfo = this.tableData[item.firstIndex]
            let firstIndex = projectList.findIndex((info) => { return info.id == firstInfo.id })
            if(thirdIndex == -1){
              list.push({ projectId: thirdInfo.id })
            }
            if(secondIndex == -1){
              list.push({ projectId: secondInfo.id })
            }
            if(firstIndex == -1){
              list.push({ projectId: firstInfo.id })
            }
            projectList = projectList.concat(list)
            score = item.type != '1' ? score : (score + Number(item.score))
            topic = topic + 1
          }
        })
        params.totalScore = score
        params.totalQuestions = topic
        params.itemTemplateTempList = projectList
        let url = ""
        if(this.type == 'add' || this.type  == 'copy'){
          url = "/supervise/template/save"
        } else if(this.type == 'edit'){
          url = "/supervise/template/updateById"
          params.templateId = this.id
        }
        this.$api.handleTemplateFun(url,params).then((res) => {
          if(res.data.code == 0){
            this.buttonloading = false
            this.editTab = '3'
            // if(this.type == 'add'){
              this.id = res.data.data
            // }
            this.getViewSelection()
          } else {
            this.buttonloading = false
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.buttonloading = false
        })

      }
    },
    //处理预览下拉变化事件
    handleProjectLevelChange(val){
      this.projectContent = val
      this.getViewList()
    },
    //获取预览选择下拉
    getViewSelection(){
      this.$api.getProjectFirstLevelById(this.id).then((res) => {
        if(res.data.code == 0){
          let list = [].concat([{ id: '', name: '全部' }]).concat(res.data.data)
          this.projectContentOptions = list
          // this.projectContent = res.data.data && res.data.data.length > 0 ? res.data.data[0].id : ''
          this.getViewList()
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {

      })
    },
    //获取预览list
    getViewList(){
      this.$api.getTemplateViewInfoById(this.id,{ projectId: this.projectContent }).then((res) => {
        if(res.data.code == 0){
          let list = res.data.data.oneLevelList
          let topicNum = 1
          list.map((item) => {
            if(item.fourLevelProject && item.fourLevelProject.length > 0){
              item.fourLevelProject.map((info) => {
                info.optionsNum = topicNum
                topicNum++
                if(info.options && info.options.length > 0){
                  info.options.map((optionInfo) => {
                    optionInfo.projectName = optionInfo.projectName.split(" ")[0]
                  })
                }
              })
            }
            item.optionsList = item.fourLevelProject
          })
          this.projectContentList = list
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {

      })
    },
    //获取列表信息
    getProjectList(){
      // companyLabel
      
      let param = {
        showLevel: this.jumpType == 'headquarters' ? '1' : '',
        supervisionMethod: this.superviseTemplateForm.supervisionMethod,
        isEnable: '1',
        branchId:this.jumpType == 'headquarters' ? null : this.userInfo.schoolList[0].companyLabel,
      }
      this.$api.getTemplateProjectTree(param).then((res) => {
        if(res.data.code == 0){
          console.log(res.data.data)
          this.getTableData(res.data.data)
        } else {
          this.tableLoading = false
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.tableLoading = false
      })
    },
    //获取树表数据
    getTableData(data){
      let list = data;
      let allList = [];
      let expandRow = []
      let expandRowItem = []
      list.map((item,index) => {
        // item.level = '1'
        item.sortIndex = index
        item.firstIndex = index
        item.sendIndex = null
        item.thirdIndex = null
        item.fourIndex = null
        item.isHaveFour = false
        allList.push(item)
        expandRow.push(item.id);
        expandRowItem.push(item)
        if(index == list.length - 1){
          item.isLast = 0
        } else {
          item.isLast = 1
        }
        if(item.supProList && item.supProList.length > 0){
          let itemTotal = 0
          item.supProList.map((info,i) => {
            // info.level = '2'
            info.sortIndex = i
            info.firstIndex = index
            info.sendIndex = i
            info.thirdIndex = null
            info.fourIndex = null
            allList.push(info)
            expandRow.push(info.id);
            expandRowItem.push(info)
            if(i == item.supProList.length - 1){
              info.isLast = 0
            } else {
              info.isLast = 1
            }
            if(info.supProList && info.supProList.length > 0){
              let infoTotal = null
              info.supProList.map((thirdInfo,j) => {
                // thirdInfo.level = '3'
                thirdInfo.sortIndex = j
                thirdInfo.firstIndex = index
                thirdInfo.sendIndex = i
                thirdInfo.thirdIndex = j
                thirdInfo.fourIndex = null
                allList.push(thirdInfo)
                expandRow.push(thirdInfo.id);
                expandRowItem.push(thirdInfo)
                if(j == info.supProList.length - 1){
                  thirdInfo.isLast = 0
                } else {
                  thirdInfo.isLast = 1
                }
                if(thirdInfo.supProList && thirdInfo.supProList.length > 0){
                  let thirdInfoTotal = null
                  item.isHaveFour = true
                  thirdInfo.supProList.map((fourInfo,fourIndex) => {
                    // fourInfo.level = '4'
                    delete fourInfo.supProList
                    fourInfo.sortIndex = fourIndex
                    fourInfo.firstIndex = index
                    fourInfo.sendIndex = i
                    fourInfo.thirdIndex = j
                    fourInfo.fourIndex = fourIndex
                    fourInfo.isEnableStatus = fourInfo.isEnable === '1' ? true : false
                    allList.push(fourInfo)
                    expandRow.push(fourInfo.id);
                    expandRowItem.push(fourInfo)
                    if(fourIndex == thirdInfo.supProList.length - 1){
                      fourInfo.isLast = 0
                    } else {
                      fourInfo.isLast = 1
                    }
                    thirdInfoTotal = thirdInfoTotal === null ? (fourInfo.type == '1' ? Number(fourInfo.score)+0 : 0) : (fourInfo.type == '1' ? thirdInfoTotal+Number(fourInfo.score) : thirdInfoTotal+0)
                  })
                  thirdInfo.score = thirdInfoTotal
                  infoTotal = thirdInfoTotal !== null ? (infoTotal === null ? thirdInfoTotal+0 : thirdInfoTotal+infoTotal) : null
                } else {
                  thirdInfo.score = ''
                  delete thirdInfo.supProList
                }
              })
              info.score = infoTotal !== null ? infoTotal : ''
              itemTotal = infoTotal !== null ? (itemTotal === null ? itemTotal+0 : infoTotal+itemTotal) : null
            } else {
              info.score = ''
              delete info.supProList
            }
          })
          item.score = itemTotal !== null ? itemTotal : ''
        } else {
          item.score = ''
          delete item.supProList
        }
      })

      this.tableData = list
      this.tableDataAll = allList
      this.tableLoading = false
      this.$nextTick(() => {
        this.expandRow = JSON.parse(JSON.stringify(expandRow))
        this.expandRowItem = JSON.parse(JSON.stringify(expandRowItem))
        this.resetTabDate(expandRowItem, 'all')
        let selectData = []
        if(this.itemTemplateTempList.length > 0){
          this.itemTemplateTempList.map((item) => {
            let index = selectData.findIndex((info) => { return info.id === item.id })
            let tableIndex = allList.findIndex((info) => { return info.id === item.id })
            if(item.level === '4' && index === -1){
              selectData.push(allList[tableIndex])
              let upInfoList = this.getUpChooseList(item.level,allList[tableIndex],selectData)
              selectData = upInfoList
            }
          })
        }
        this.tableSelectData = selectData;
        if(this.type == 'view'){
          this.$refs.projectTable.doLayout()
        } else {
          setTimeout(() => {//再对刚才拿到的需要选中的选项进行选中效果添加
            selectData.map((item) => {
              this.$refs.projectTable.toggleRowSelection(item,true)
            })
          },300)
        }
      })
    },
    //展开菜单
    handleExpension(val){
       let expandRowItem = []
      let expandRow = []
      setTimeout(() => {
        switch(val){
          case '2':
            this.tableData.map((item) => {
              expandRow.push(item.id);
              expandRowItem.push(item)
            });
            break;
          case '3':
            this.tableData.map((item) => {
              expandRow.push(item.id);
              expandRowItem.push(item)
              if (item.supProList && item.supProList.length > 0) {
                item.supProList.map((itemChild) => {
                  expandRow.push(itemChild.id);
                  expandRowItem.push(itemChild)
                });
              }
            });
            break;
          case 'all': 
            this.tableData.map((item) => {
              expandRow.push(item.id);
              expandRowItem.push(item)
              if (item.supProList && item.supProList.length > 0) {
                item.supProList.map((itemChild) => {
                  expandRow.push(itemChild.id);
                  expandRowItem.push(itemChild)
                  if (itemChild.supProList && itemChild.supProList.length > 0) {
                    itemChild.supProList.map((itemThreeChild) => {
                      expandRow.push(itemThreeChild.id);
                      expandRowItem.push(itemThreeChild)
                      if (itemThreeChild.supProList && itemThreeChild.supProList.length > 0) {
                        itemThreeChild.supProList.map((itemFourChild) => {
                          expandRow.push(itemFourChild.id);
                          expandRowItem.push(itemFourChild)
                        });
                      }
                    });
                  }
                });
              }
            });
            break;
          default:
            break;
        }
        this.$nextTick(() => {
          this.expandRow = JSON.parse(JSON.stringify(expandRow))
          this.expandRowItem = JSON.parse(JSON.stringify(expandRowItem))
          console.log(expandRow)
          console.log(expandRowItem)
          this.resetTabDate(expandRowItem, val !== 'all' ? (Number(val) - 1)+"" : val)
        })
      },500)
    },
    //收起展开菜单
    resetTabDate(arr,type) {
      let data = arr || this.tableData;
      if(arr.length == 0){
        this.tableData.map((item) => {
          this.$refs.projectTable.toggleRowExpansion(item,false);
          if ( Object.prototype.toString.call(item.supProList) == "[object Array]") {
            this.resetTabDate(item.supProList,type);
          }
        })
        return
      }
      data.forEach((item) => {
        if(type !== 'all'){
          if(Number(item.level) <= Number(type)){
            this.$refs.projectTable.toggleRowExpansion(item,true);
          } else {
            this.$refs.projectTable.toggleRowExpansion(item,false);
          }
        } else {
          this.$refs.projectTable.toggleRowExpansion(item,true);
        }
        
        if ( Object.prototype.toString.call(item.supProList) == "[object Array]") {
          this.resetTabDate(item.supProList,type);
        }
      });
    },
  },
  beforeDestroy(){
    sessionStorage.removeItem("nodeInfo")
    window.removeEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
.contain {
  width: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .header-title{
    width: 100%;
    color: #3D3E4F;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .return-button {
      &.el-button:focus{
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
      &.el-button:hover{
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
    }
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }
  .tab-button{
    width: 100%;
    margin-bottom: 32px;
    .tab-item{
      width: 150px;
      height: 39px;
      line-height: 39px;
      color: #A8A6B9;
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      &.first{
        position: relative;
        z-index: 3;
      }
      &.second{
        position: relative;
        z-index: 2;
      }
      &.third{
        position: relative;
        z-index: 1;
      }
      &.active{
        color: #FFFFFF;
      }
    }
  }
  .dashed-line{
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #E2E7F0;
    margin: 40px 0 30px 0;
  }
  .form {
    width: calc(100% - 80px);
    padding: 0 40px;
    // min-height: 696px;
    .form-title{
      width: 100%;
      color: #3D3E4F;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    /deep/.el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/.el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
    /deep/.el-switch__label.is-active{
      color: #010334;
    }
    /deep/.el-switch__label{
      color: #010334;
    }
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #003685;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #003685 !important;
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__inner:hover {
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__label{
      color: #010334 !important;
    }
    /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner{
      background-color: #003685 !important;
      border-color: #003685 !important;
    }
    .icon-show-img{
      width: 50px;
      height: 50px;
      background: #EAF1FC;
      border-radius: 8px;
      display: block;
    }
    /deep/.el-table__footer-wrapper tbody td{
      color: #C6000B;
      font-size: 14px;
    }
    /deep/.el-table__fixed-footer-wrapper tbody td{
      color: #003685;
    }

    .success-info{
      margin-top: 35px;
      width: 100%;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        width: 80px;
        height: 80px;
        margin-bottom: 24px;
      }
      .success-text{
        font-size: 24px;
        font-weight: 400;
        color: #010334;
      }
    }

    .view-buttons{
      width: 100%;
      min-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      .button-item{
        width: 86px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #B5BDCF;
        border-radius: 4px;
        color: #3D3E4F;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-right: 10px;
        cursor: pointer;
        &.active{
          color: #003685;
          border-color: #003685;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .view-body{
      width: 100%;
      margin-top: 60px;
      .view-body-title{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #010334;
        margin-bottom: 20px;
        text-align: center;
      }
      .view-body-remark{
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #3D3E4F;
      }
      .phone-view-inside{
        width: 375px;
        height: 760px;
        box-sizing: border-box;
        padding: 50px 30px;
      }
      .view-body-content-choose{
        width: 100%;
        display: flex;
        align-items: center;
        .label{
          font-size: 16px;
          font-weight: 400;
          color: #8C94A7;
        }
        &.phone{
          .el-select{
            width: 100px;
            height: 26px;
            line-height: 26px;
            /deep/.el-input__inner{
              height: 26px;
              line-height: 26px;
            }
            /deep/.el-input__icon{
              height: 26px;
              line-height: 26px;
            }
          }
        }
      }
      .view-body-content-list{
        width: 100%;
        .view-body-content-item{
          width: 100%;
          .item-title{
            width: 100%;
            color: #010334;
            font-size: 16px;
            font-weight: bold;
          }
          .item-options{
            width: 100%;
            margin-bottom: 40px;
            .options-question{
              width: 100%;
              font-size: 15px;
              font-weight: 400;
              color: #010334;
              .options-item{
                width: 100%;
                margin-top: 20px;
                margin-left: 32px;
                font-size: 15px;
                font-weight: 400;
                color: #3D3E4F;
                .item-info{
                  display: flex;
                  align-items: center;
                  .circle{
                    border: 1px solid #A7A5B9;
                    width: 12px;
                    height: 12px;
                    background-color: #FFFFFF;
                    margin-right: 10px;
                  }
                }
                .score{
                  margin-left: 20px;
                }
                &.phone{
                  margin: 20px 3px 0 2px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 10px;
                  .item-info{
                    width: calc(100% - 100px);
                    .circle{
                      width: 10px;
                      height: 10px;
                      margin-right: 8px;
                    }
                  }
                  .score{
                    width: 80px;
                  }
                }
              }
              &.phone{
                font-size: 12px;
              }
            }
            &.phone{
              width: calc(100% - 16px);
              padding: 12px 8px;
              background-color: #FFFFFF;
              border-radius: 4px;
              margin-bottom: 15px;
            }
          }
        }
      }
      &.phone{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .expand-radio{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .text-button{
    margin-right: 10px;
    margin-left: 0;
  }
  .form-btn {
    width: 100%;
    margin-top: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
}
</style>
